import React, { useEffect, useState } from 'react'

function PcHeader() {

  return (
      <header>
        <h1>Create a Spotify playlist, from free form list</h1>
      </header>
  )
}

export default PcHeader
