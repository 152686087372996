import React, { useEffect, useState } from 'react'
import Scopes from "./Scopes"
import PcHeader from "./PcHeader"
import PlaylistCreater from "./PlaylistCreater"
import { SpotifyApiContext, User, UserTop } from 'react-spotify-api'
import SpotifyAuth from "./SpotifyAuth";
import Container from 'react-bootstrap/Container';
import Cookies from 'js-cookie'

const dev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const App = () => {

  const [spotifyAuthToken, setSpotifyAuthToken] = useState(null)

  useEffect(() => {
    //delay so cookie can appear :(
    const timeout = setTimeout(() => {
      setSpotifyAuthToken(Cookies.get('spotifyAuthToken'))
    }, 500)
    return () => clearTimeout(timeout)
  }, [Cookies.get('spotifyAuthToken')])

  const logout = () => {
    //window.localStorage.removeItem('spotifyAuthToken')
    Cookies.remove('spotifyAuthToken', { path: '' })
  }

  function loggedIn() {
    let foo = Cookies.get('spotifyAuthToken')
    console.log('cookie:' + foo)
    return foo
  }

  return (
    <div>
      <PcHeader></PcHeader>
      {spotifyAuthToken ? (
          <Container fluid>
            <SpotifyApiContext.Provider value={spotifyAuthToken}>
              <PlaylistCreater></PlaylistCreater>
            </SpotifyApiContext.Provider>
          </Container>
      ) : (
          <div>
            <p>Get started by logging in to Spotify</p>
            <div className='spotifyBtn'>
              <SpotifyAuth
                  redirectUri={
                    dev
                        ? 'http://localhost:3000/authcb'
                        : 'https://playlist.robinbagot.com/authcb'
                  }
                  clientID='0c6c0ac5c6ea44a9a9c699792b6fa8fd'
                  scopes={[
                    Scopes.userReadPrivate,
                    Scopes.userReadEmail,
                    Scopes.userTopRead,
                    Scopes.playlistModifyPrivate,
                    Scopes.playlistModifyPrivate
                  ]}
              />
            </div>
          </div>
      )}
    </div>
  )
}

export default App
