/**
 * @class SpotifyAuth
 */

import React, { Component } from 'react'
import scopes from './Scopes'
import styles from './SpotifyAuth.css'
import t from 'prop-types'
import SpotifyLogo from './SpotifyLogo'
import Cookies from 'js-cookie'

class SpotifyAuth extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isAuthenticatedWithSpotify: false
    }
  }

  getAuthorizeUri(clientId, scope, redirectUri, showDialog, codeChallenge) {
    let args = new URLSearchParams({
      response_type: 'code',
      client_id: clientId,
      scope: scope,
      redirect_uri: redirectUri,
      state: this.generateRandomString(16),
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
      show_dialog: Boolean(showDialog)
    })

    return 'https://accounts.spotify.com/authorize?' + args
  }

  generateRandomString(length) {
    let text = ''
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
  }

  async pkceChallengeFromVerifier(v) {
    function sha256(plain) {
      // returns promise ArrayBuffer
      const encoder = new TextEncoder()
      const data = encoder.encode(plain)
      return window.crypto.subtle.digest('SHA-256', data)
    }
    function base64encode(string) {
      // Convert the ArrayBuffer to string using Uint8 array.
      // btoa takes chars from 0-255 and base64 encodes.
      // Then convert the base64 encoded to base64url encoded.
      // (replace + with -, replace / with _, trim trailing =)
      return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
          .replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=+$/, '');
    }

    let hashed = await sha256(v)
    return base64encode(hashed)
  }

  getCodeVerifier() {
    let codeVerifier = localStorage.getItem('code-verifier')
    if (!codeVerifier) {
      codeVerifier = this.generateRandomString(50)
      localStorage.setItem('code-verifier', codeVerifier)
    }
    return codeVerifier
  }

  componentDidMount() {
    if (this.fetchPromise) {
      // already mounted previously
      return
    }

    const urlParams = new URLSearchParams(window.location.search)
    let code = urlParams.get('code')

    if (!code) {
      // not doing callback
      return
    }

    let codeVerifier = this.getCodeVerifier()

    let body = new URLSearchParams({
      grant_type: 'authorization_code',
      code: code,
      redirect_uri: this.props.redirectUri,
      client_id: this.props.clientID,
      code_verifier: codeVerifier
    });

    this.fetchPromise = fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: body
    }).then(response => {
      if (!response.ok) {
        throw new Error('HTTP status ' + response.status);
      }
      return response.json();
    }).then(data => {
      if (this.props.cookie) {
        const in60Minutes = 1 / 24
        Cookies.set('spotifyAuthToken', data.access_token, { expires: in60Minutes})
      }
      if (this.props.localStorage) {
        window.localStorage.setItem('spotifyAuthToken', data.access_token)
      }
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  async handleClick(event) {
    event.preventDefault()

    let codeVerifier = this.getCodeVerifier()

    let codeChallenge = await this.pkceChallengeFromVerifier(codeVerifier)

    window.location = this.getAuthorizeUri(
        this.props.clientID,
        this.props.scopes,
        this.props.redirectUri,
        this.props.showDialog,
        codeChallenge
    )
  }

  render() {
    return (
      <button
        className={this.props.btnClassName || styles.rsaSpotifyBtn}
        onClick={(event) => this.handleClick(event)}
      >
        {!this.props.noLogo && (
          <SpotifyLogo className={this.props.logoClassName} />
        )}
        <span>{this.props.title}</span>
      </button>
    )
  }
}

SpotifyAuth.propTypes = {
  redirectUri: t.string.isRequired,
  clientID: t.string.isRequired,
  scopes: t.arrayOf(t.string),
  onAccessToken: t.func,
  logoClassName: t.string,
  title: t.string,
  noLogo: t.bool,
  cookie: t.bool,
  showDialog: t.bool,
  localStorage: t.bool
}

SpotifyAuth.defaultProps = {
  redirectUri: 'http://localhost:3000/callback',
  scopes: [scopes.userReadPrivate, scopes.userReadEmail],
  onAccessToken: (token) => {},
  title: 'Continue with Spotify',
  localStorage: false,
  noLogo: false,
  cookie: true,
  showDialog: false,
}

export default SpotifyAuth
