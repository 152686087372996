import React, { useEffect, useState, useMemo } from 'react'
import Row from "react-bootstrap/Row";
import {Button, Card, Form} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image'
import SpotifyWebApi from "spotify-web-api-js";
import { SpotifyApiContext, useUser, User } from 'react-spotify-api'
import { forEachOf, forEachOfSeries } from "async-es";

function PlaylistCreater(props) {

    const [playlist, setPlaylist] = useState()
    const [playlistName, setPlaylistName] = useState()
    const [statusMessage, setStatusMessage] = useState('')

    let user = useUser()

    let spotifyApi = new SpotifyWebApi()

    const token = React.useContext(SpotifyApiContext)

    let track_uris = []

    useMemo(() => {
        console.log('This is useMemo')
        spotifyApi.setAccessToken('' + token)
        console.log('token:' + token)
    }, []);

    let handleTrackListChange = (e) => {
        setPlaylist(e.target.value)
    }

    let handleNameChange = (e) => {
        setPlaylistName(e.target.value)
    }

    let handleClickCreate = () => {
        console.log('playlist:' + playlist)

        track_uris = [] // reset needed?
        const playlistArray = preprocessPlaylist(playlist)

        if (playlistArray && playlistArray.length > 0) {

            forEachOfSeries(playlistArray, getTrackUri)
                .then( () => {
                    console.log('track_uris:' + track_uris)
                    createPlaylist()
                }).catch( err => {
                    console.error(err)
                    setStatusMessage('Could not search tracks ' + err)
                })
        }
    }

    let preprocessPlaylist = (playlist) => {
        let playlistArray = playlist.split('\n')

        for (let i = 0; i < playlistArray.length; i++) {
            let cleaned = playlistArray[i].trim().replace(/-/g, '').replace(/\"/g, '')
            playlistArray[i] = cleaned
        }

        return playlistArray
    }

    let getTrackUri = (trackString, key, callback) => {
        spotifyApi.searchTracks(trackString, {limit: 1})
            .then(
                function (data) {
                    if (data && data.tracks && data.tracks.items && data.tracks.items.length > 0) {
                        console.log('data:' + data.tracks.items[0].uri)
                        track_uris.push(data.tracks.items[0].uri)
                    }
                    callback()
                },
                function (err) {
                    console.error(err)
                    return callback(err)
                }
            )
    }

    let createPlaylist = () => {
        if (track_uris && track_uris.length > 0) {
            spotifyApi.createPlaylist(user.data.id, {
                name: playlistName,
                description: "Another BSD playlist!",
                public: false
            }).then(
                function (data) {
                    let playlistId = data.id
                    console.log('playlist created id:' + playlistId)

                    spotifyApi.addTracksToPlaylist(playlistId, track_uris)
                        .then(
                            function (data) {
                                console.log('playlist updated with tracks')
                                setStatusMessage(`Playlist ${playlistName} was created with ${track_uris.length} tracks`)
                            },
                            function (err) {
                                console.error(err)
                                setStatusMessage('Could not add track to playlist' + err)
                            }
                        )
                },
                function (err) {
                    console.error(err)
                    setStatusMessage('Could not create playlist' + err)
                }
            )
        }
    }

    return (
        <User>
        {(user) =>
            user && user.data ? (
                <>
                    <Row className={'justify-content-left'}>
                        <Col sm={1}>
                            <Image style={{width: '5rem'}} className={'float-left'}
                                   src={user.data.images && user.data.images.length>0 ? user.data.images[0].url : ''}
                                   alt='Your Spotify Profile Picture'/>
                        </Col>
                        <Col sm={1}>
                            Hi, {user.data.display_name}.
                        </Col>
                        <Col sm className={'justify-content-left'}>You can create a new playlist by typing or pasting a list of songs, one song per line.</Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col sm={2}>
                            <div>
                                <p>Add your songs, one per line. You're free to enter anything, the more info the better match, e.g. We found love Rihanna</p>
                            </div>
                            <div>
                                <Button onClick={handleClickCreate}>Create Playlist</Button>
                            </div>
                            <div>
                                <p>{statusMessage}</p>
                            </div>
                        </Col>
                        <Col sm>
                            <Form>
                                <Form.Group controlId="createInput">
                                    <Form.Label>Playlist name</Form.Label>
                                    <Form.Control type="text"
                                                  placeholder="Playlist name"
                                                  value={playlistName}
                                                  onChange={handleNameChange}>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                            <Form>
                                <Form.Group controlId="createInput">
                                    <Form.Label>List of tracks</Form.Label>
                                    <Form.Control as="textarea" rows="25"
                                                  placeholder="List of tracks..."
                                                  value={playlist}
                                                  onChange={handleTrackListChange}>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </>
            ) : (
                <p>Loading...</p>
            )
        }
        </User>
)
}

export default PlaylistCreater
